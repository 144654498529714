import React from 'react';
import { BrowserRouter as Router, Link, Switch, Route } from 'react-router-dom';

import OnCourseLogo from './assets/oncourse-logo.png';
import Navbar from './components/shared/Navbar';
import Routes from './components/routes/Routes';
import PrivateRoute from './components/routes/PrivateRoute';
import ScrollToTop from './components/shared/ScrollToTop';
import NavbarTitle from './components/shared/navbar/NavbarTitle';
import NavbarTitleAddition from './components/shared/navbar/NavbarTitleAddition';
import InstructionToggle from './components/shared/instructions/InstructionToggle';

function App() {
    return (
        <Router basename={process.env.REACT_APP_BASENAME}>
            <ScrollToTop>
                <div id="header" className="flex-fill">
                    <Link to="/">
                        <img id="on-course-logo" src={OnCourseLogo} width="200px" alt="On Course Logo" />
                    </Link>
                </div>

                <Switch>
                    {/* Render Navbar for route */}
                    {Routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={Navbar}
                        />
                    ))}
                    <Route render={props => <Navbar route={Routes[0]} />} />
                </Switch>

                <div className="container">
                    <div className="row justify-content-center">
                        <div id="content" className="col-12">
                            {/* Render route title */}
                            <div className="row justify-content-center">
                                <div className="col-md-5">
                                    <h1 className="navbar-title navbar-brand">
                                        {Routes.map((route, index) =>
                                            <Route
                                                key={index}
                                                path={route.path}
                                                exact={route.exact}
                                                component={NavbarTitle} />
                                        )}
                                        {Routes.map((route, index) =>
                                            <Route
                                                key={index}
                                                path={route.path}
                                                exact={route.exact}
                                                component={NavbarTitleAddition} />
                                        )}
                                    </h1>
                                </div>

                                {/* Show route description */}
                                {Routes.map((route, index) => {
                                    return <Route exact={true} key={index} path={route.path} render={p => {
                                        if (route.description)
                                            return (
                                                <div className="route-descr col-1">
                                                    <InstructionToggle description={route.description} />
                                                </div>
                                            )
                                    }} />
                                })}
                            </div>

                            {/* Render the matching content routes */}
                            <Switch>
                                {Routes.map((route, index) => {
                                    if (route.private)
                                        return (
                                            <PrivateRoute
                                                key={index}
                                                path={route.path}
                                                exact={route.exact}
                                                component={route.component}
                                                context={route}
                                            />
                                        );
                                    return (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    );
                                })}
                                {/* Render page not found */}
                                <Route component={Routes[0].component} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </ScrollToTop>
        </Router>
    )
}

export default App;