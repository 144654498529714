import React, { Component } from 'react';
import { Route, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import Routes from '../routes/Routes';
import LogoutButton from './navbar/LogoutButton';
import NavbarTitle from './navbar/NavbarTitle';
import NavbarTitleAddition from './navbar/NavbarTitleAddition';
import MIAService from '../../services/MIA/MIAService';
import InstructionToggle from '../shared/instructions/InstructionToggle';

class Navbar extends Component {

    constructor(props) {
        super(props);

        let route = Routes.find(r => r.path === ((props.match && props.match.path) || props.route.path));

        this.state = {
            navCollapse: "collapse navbar-collapse",
            title: route && route.title,
            authenticated: MIAService.getInstance().isAuthenticated(),
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        if (this.state.navCollapse === "collapse navbar-collapse")
            this.setState({ navCollapse: "collapse navbar-collapse show" });
        else
            this.setState({ navCollapse: "collapse navbar-collapse" });
    }

    render() {
        return (
            <nav className="navbar sticky-top navbar-expand-md navbar-dark bg-primary">
                <button className="navbar-toggler" onClick={this.toggle} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <h1 className="navbar-title navbar-brand">
                    <NavbarTitle {...this.props} />
                    <NavbarTitleAddition {...this.props} />
                </h1>

                {/* Show route description */}
                {Routes.map((route, index) => {
                    return <Route exact={true} key={index} path={route.path} render={p => {
                        if (route.description)
                            return (
                                <div className="route-descr">
                                    <InstructionToggle description={route.description} />
                                </div>
                            )
                    }} />
                })}

                <div className={this.state.navCollapse} id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        {Routes.map((route, index) =>
                            (this._shouldRenderOption(route)) ? (
                                <li key={index} className="nav-item">
                                    <NavLink exact={true} className="nav-link" to={this._alterPath(route.path)}>
                                        {route.title}<span className="sr-only">(current)</span>
                                    </NavLink>
                                </li>
                            ) : null
                        )}
                        <li key={Routes.length} className="nav-item">
                            <a className="nav-link" href="https://www.rsm.nl/about-rsm/privacy-statement/" target="_blank" rel="noopener noreferrer">
                                Privacy <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" />
                            </a>
                        </li>
                    </ul>
                    <LogoutButton authenticated={this.state.authenticated} />
                </div>

                {/* Render a spacer if no description button is shown */}
                {Routes.map((route, index) => {
                    return <Route exact={true} key={index} path={route.path} render={p => 
                        !route.description ? <div>{/* spacer */}</div> : null
                    } />
                })}

                {/* <BackButton /> */}
            </nav>
        );
    }

    _alterPath(path) {
        if (path === '/about/:code')
            path =  (MIAService.getInstance().userSession.activity ? '/about/' + MIAService.getInstance().userSession.activity.assignmentCode : '/about/no-questionnaire-available');
        return path;
    }

    _shouldRenderOption(route) {
        // Only show login option for non-authenticated users
        let renderLogin = ((route.includeInNavBar && route.id !== 'login' && route.private && this.state.authenticated) ||
            (route.includeInNavBar && route.id === 'login' && !this.state.authenticated));

        // Check if the user has the correct role
        let renderForRole = (!route.roles || (route.roles && (this.state.authenticated && MIAService.getInstance().userSession.includesRoles(route.roles))));

        // Don't show any menu option for logging in via /auth/:token
        let isAuthLogin = (this.props.match.path === '/auth/:token');

        return !isAuthLogin && renderLogin && renderForRole;
    }

}

export default Navbar;