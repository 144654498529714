import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

import $ from 'jquery';
import 'bootstrap/js/src/popover';
import Utils from '../Utils';

class InstructionToggle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            description: props.description,
            stripHtml: props.stripHtml === undefined ? true : props.stripHtml
        }
    }

    componentDidMount() {
        $('[data-toggle="popover"]').popover({
            html: !this.state.stripHtml,
            template: (this.state.description.length > 500 ?
                '<div class="popover wide-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>' :
                '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'),
            container: 'body'
        });
    }

    componentWillUnmount() {
        $('[data-toggle="popover"]').popover('dispose');
    }

    render() {
        return (
            this.state.description ?
                <button
                    tabIndex="0"
                    type="button"
                    className="btn btn-info description-toggle"
                    data-container="body"
                    data-toggle="popover"
                    data-trigger="focus"
                    data-content={ this.state.stripHtml ? Utils.StripHTMLFromString(this.state.description) : ( this.state.description ) }>
                    <FontAwesomeIcon icon={faQuestion} />
                </button>
                : null
        );
    }
}

export default InstructionToggle;