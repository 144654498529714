import React from 'react';
import LoadIndicator from '../../shared/LoadIndicator';
import MIAService from '../../../services/MIA/MIAService';
import ErrorMessage from '../../shared/ErrorMessage';
import { primaryColor } from '../../../scss/styles.scss';
import PeriodicAnswersChart from './chart/PeriodicAnswersChart';

class TeamOverview extends React.Component {

    constructor(props) {
        super(props);

        this.questions = this.props.questions;

        // Set the state
        this.state = {
            teamId: this.props.teamId,
            teamName: this.props.teamName,
            assignmentId: this._GetAssignmentId(this.questions),
            question: null,
            error: null,
            nonRespondents: null,
            mostNegativeUser: null,
            mostPositiveUser: null,
        };
    }

    componentDidMount() {
        MIAService.getInstance().getReport('overview-v2', { teamId: this.state.teamId, assignmentId: this.state.assignmentId })
            .then(resp => resp.json())
            .then(result => {
                // Get periodic chart data
                let data = [];

                result.answers.forEach(r => {
                    let questionId = parseInt(r.QuestionId);
                    let datasetIndex = data.findIndex(d => d.questionId === questionId);

                    if (datasetIndex < 0) {
                        datasetIndex = data.length;
                        data[datasetIndex] = {
                            questionId: questionId,
                            labels: [],
                            datasets: [{
                                label: this.state.teamName,
                                showLine: true,
                                borderColor: primaryColor,
                                pointBackgroundColor: primaryColor,
                                tension: 0,
                                fill: false,
                                data: [],
                            },
                            {
                                label: 'Average',
                                showLine: true,
                                borderColor: 'lightgray',
                                pointBackgroundColor: 'lightgray',
                                tension: 0,
                                fill: false,
                                data: [],
                            }]
                        };
                    }

                    // Add data point
                    data[datasetIndex].datasets[0].data.push({
                        x: data[datasetIndex].datasets[0].data.length + 1,
                        y: r.Score,
                    });

                    data[datasetIndex].datasets[1].data.push({
                        x: data[datasetIndex].datasets[1].data.length + 1,
                        y: r.TeamAverage,
                    })

                    // Add label
                    data[datasetIndex].labels.push(data[datasetIndex].datasets[0].data.length);
                });

                this.setState({ chartData: data });
            })
            .catch(error => this.setState({ error: error.message }));

        // Load most positive and most negative person
        MIAService.getInstance().getReport('839f6ce4-903f-4ba9-9556-ab7f07109fef', { teamId: this.state.teamId })
            .then(resp => resp.json())
            .then(result => {
                let lowestAnswer = this._GetAnswerLowestScore(result.answers);
                let highestAnswer = this._GetAnswerHighestScore(result.answers);
                let nonRespondents = this._GetNonrespondents(result.answers);

                if (result.answers)
                    this.setState({ mostNegativeUser: (lowestAnswer && lowestAnswer.UserName), mostPositiveUser: (highestAnswer && highestAnswer.UserName), nonRespondents: nonRespondents });
            })
            .catch(error => this.setState({ error: error.message }));
    }

    render() {
        return (
            <div className="row" >
                <div className="col-12">
                    {this.state.error && <ErrorMessage error={this.state.error} />}
                    <div>
                        {!this.state.nonRespondents ?
                            (<LoadIndicator />) :
                            (
                                <div className="row">
                                    <div className="col-12">
                                        {this.state.mostNegativeUser &&
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>Most negative scores: {this.state.mostNegativeUser}</p>
                                                </div>
                                            </div>}
                                        {this.state.mostPositiveUser &&
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>Most positive scores: {this.state.mostPositiveUser}</p>
                                                </div>
                                            </div>}
                                        {this.state.nonRespondents && this.state.nonRespondents.length > 0 &&
                                            <div className="row">
                                                <div className="col-12">
                                                    Nonrespondents: {this.state.nonRespondents.map((nr, index) => {
                                                        return <span key={index}>{nr.UserName}{index !== this.state.nonRespondents.length - 1 && ', '}</span>
                                                    })}
                                                </div>
                                            </div>}
                                            <hr />
                                    </div>
                                </div>
                            )
                        }
                        {!this.state.chartData ?
                            (<LoadIndicator />) : (
                                <div className="row">
                                    {this.state.chartData.map((cd, index) => {
                                        let q = this._GetQuestion(cd.questionId);
                                        return (
                                            <div key={index} className="col-md-6 overview-chart">
                                                <h2 dangerouslySetInnerHTML={{ __html: q.Title }}></h2>
                                                <p dangerouslySetInnerHTML={{ __html: q.QuestionText }}></p>
                                                <PeriodicAnswersChart dataSets={cd} legend={{ display: true }} />
                                            </div>
                                        );
                                    })}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }

    _GetQuestion(questionId) {
        return this.questions.find(q => q.Id === questionId);
    }

    _GetAssignmentId(questions) {
        let q = questions.find(q => q.AssignmentId);
        return q.AssignmentId;
    }

    _GetAnswerLowestScore(answers) {
        let lowestAnswer = { Score: 101 };
        answers.forEach(a => {
            if (a.Score !== null && a.Score < lowestAnswer.Score)
                lowestAnswer = a;
        });
        return lowestAnswer;
    }

    _GetAnswerHighestScore(answers) {
        let highestAnswer = { Score: -1 };
        answers.forEach(a => {
            if (a.Score !== null && a.Score > highestAnswer.Score)
                highestAnswer = a;
        });
        return highestAnswer;
    }

    _GetNonrespondents(answers) {
        let nonRespondents = [];

        answers.forEach(a => {
            if (a.Score === null)
                nonRespondents.push(a);
        });

        return nonRespondents;
    }

}

export default TeamOverview;
