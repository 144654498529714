import { primaryColor, badStatus, neutralStatus, goodStatus } from '../../scss/styles.scss';

const negativeThreshold = 40;
const positiveThreshold = 75;

const dutchDateTime = new Intl.DateTimeFormat(
    'nl-NL', {
    year: '2-digit', month: '2-digit', day: '2-digit',
    hour: '2-digit', minute: '2-digit', timeZoneName: 'short'
}
).format;

const htmlTagRegex = new RegExp(/(<script(\s|\S)*?<\/script>)|(<style(\s|\S)*?<\/style>)|(<!--(\s|\S)*?-->)|(<\/?(\s|\S)*?>)/g);

function DetermineStatusColor(status) {
    switch (status) {
        case 'Bad':
            return badStatus;
        case 'Neutral':
            return neutralStatus;
        case 'Good':
            return goodStatus;
        default:
            return '#fff';
    }
}

function DetermineStatus(status) {
    if (status < negativeThreshold)
        return 'Bad';
    if (status < positiveThreshold)
        return 'Neutral';
    if (status <= 100)
        return 'Good';

    return null;
}

function LimitString(string, limit, ending = '..') {
    if (string.length > limit)
        return string.substr(0, limit) + ending;
    return string;
}

function CapitalizeString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function StripHTMLFromString(string) {
    return DecodeHTMLEntities(string.toString().replace(htmlTagRegex, ""));
}

function DecodeHTMLEntities(string) {
    return string.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec);
    });
}

function DrawStatus(ctx, status, x, y, size) {
    let statusColor = DetermineStatusColor(status);

    DrawCircle(ctx, primaryColor, x, y, size);
    DrawCircle(ctx, 'white', x, y, size * .8);
    DrawCircle(ctx, statusColor, x, y, size * .6);
}

function DrawCircle(ctx, color, x, y, size) {
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(x, y, size, 0, 2 * Math.PI);
    ctx.fill();
}

function IsInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}

export default { DetermineStatus, DetermineStatusColor, LimitString, CapitalizeString, StripHTMLFromString, DecodeHTMLEntities, dutchDateTime, DrawStatus, IsInt };