import React from 'react';
import { Redirect } from 'react-router-dom';
import MIAService from '../../services/MIA/MIAService';

class Logout extends React.Component {

    componentDidMount() {
        MIAService.getInstance().logout();
    }

    render() {
        return <Redirect to="/" />;
    }

}

export default Logout;