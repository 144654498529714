import React from 'react';
import { Route } from 'react-router-dom';

import Routes from '../../../routes/Routes';
import PrevButton from '../../../shared/PrevButton';

function AssignmentControls() {

    let prevButtonRoute = Routes.find((r) => r.id === 'assignment-question');

    return (
        <div className="form-row mt-3 just justify-content-center">
            <div className="col-6">
                <Route path={prevButtonRoute.path} exact={prevButtonRoute.exact} component={PrevButton} />
            </div>
            <div className="col-6">
                <button id="nextButton" type="submit" className="btn btn-primary btn-lg">Next</button>
            </div>
        </div>
    );
}

export default AssignmentControls;