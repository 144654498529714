import React from 'react';
import MultipleChoiceModule from './modules/MultipleChoiceModule';
import SliderModule from './modules/SliderModule';
import TextModule from './modules/TextModule';

class Question extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.questionData,
            answer: this.props.answer,
            answerCallback: this.props.answerCallback,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.questionData !== this.props.questionData)
            this.setState({ data: this.props.questionData, answer: this.props.answer, answerCallback: this.props.answerCallback });
    }

    render() {
        let module;

        switch (this.state.data.moduleName) {
            case 'MultipleChoiceModule':
                module = <MultipleChoiceModule data={this.state.data} answer={this.state.answer} answerCallback={this.state.answerCallback} />;
                break;
            case 'SliderModule':
                module = <SliderModule data={this.state.data} answer={this.state.answer} answerCallback={this.state.answerCallback} />;
                break;
            case 'TextModule':
                module = <TextModule data={this.state.data} answer={this.state.answer} answerCallback={this.state.answerCallback} />;
                break;
            default:
                module = <p>Module does not exist..</p>;
                break;
        }

        return (
            <div className="row justify-content-center">
                <div id="question" className="col-md-5">
                    <h2 dangerouslySetInnerHTML={{ __html: this.state.data.title }}/>
                    <hr />

                    <h3 className="mb-3" dangerouslySetInnerHTML={{ __html: this.state.data.questionText }} />

                    {module}
                </div>
            </div>
        );
    }

}

export default Question;