import React from 'react';
import AssignmentControls from '../AssignmentControls';

class MultipleChoiceModule extends React.Component {

    initialFormState = 'needs-validation';

    constructor(props) {
        super(props);

        this.state = {
            moduleData: this.props.data,
            answerCallback: this.props.answerCallback,
            formState: this.initialFormState,
            answer: this.getRawAnswer(this.props.answer),
        };

        this.validate = this.validate.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    getRawAnswer(sessionAnswer) {
        return (sessionAnswer && sessionAnswer.answerParts && sessionAnswer.answerParts.length > 0 && sessionAnswer.answerParts[0].answerId);
    }

    getSessionAnswer(answer) {
        return {
            questionId: this.state.moduleData.id,
            answerParts: [
                { answerId: answer || this.state.answer, answerPart: 'true' }
            ]
        };
    }
    
    handleInputChange(event, choice) {
        event.preventDefault();
        this.setState({ answer: (choice ? choice.id : event.target.value), validated: false });
    }

    validate(event) {
        event.preventDefault();

        this.setState({ formState: this.initialFormState + ' was-validated' })

        if (!event.target.checkValidity())
            return;

        this.state.answerCallback(this.getSessionAnswer(this.state.answer));
    }

    render() {
        return (
            <form className={this.state.formState} onSubmit={this.validate} noValidate>
                <div className="form-group list-group">
                    {this.state.moduleData.choices.map((choice, index) => {
                        let btnClassName = (this.state.answer === choice.id ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action");
                        let btnOnClick = (e) => this.handleInputChange(e, choice);

                        return (
                            <button key={index} className={btnClassName} onClick={btnOnClick} >
                                <div className="custom-control custom-radio">
                                    <input className="custom-control-input" type="radio" name="multipleChoice" id={choice.id} value={this.state.answer} onChange={this.handleInputChange} required checked={this.state.answer === choice.id} />
                                    <label className="custom-control-label" htmlFor={choice.id}>
                                        {choice.selectionText}
                                    </label>
                                    {index === this.state.moduleData.choices.length - 1 &&
                                        (<div className="invalid-feedback">Please choose an answer.</div>)
                                    }
                                </div>
                            </button>
                        );
                    })}
                </div>

                <AssignmentControls />

            </form>
        );
    }

}

export default MultipleChoiceModule;