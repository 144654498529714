import React from 'react';
import Routes from '../../routes/Routes';

function NavbarTitleAddition(props) {

    let text = null;
    let route = Routes.find(r => r.path === ((props.match && props.match.path) || props.route.path));

    if (route.id === 'assignment-question' && props.match.params.questionIndex !== props.match.params.totalNumberOfQuestions)
        text = (parseInt(props.match.params.questionIndex) + 1) + (props.match.params.totalNumberOfQuestions && ('/' + props.match.params.totalNumberOfQuestions));

    if (text)
        return <span> {text} </span>;

    return null;
}

export default NavbarTitleAddition;