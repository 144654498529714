import Utils from '../../../shared/Utils';
import { chartAreaColor, lightRed, lightOrange, lightGreen } from '../../../../scss/styles.scss';

var PeriodicChartPlugin = {
    afterDraw: (chart, easing, options) => {
        let iconSize = 15;
        let marginLeft = iconSize / 2;
        let yAxisBox = chart.boxes.find(b => b.id === 'y-axis-0');

        // Draw status icons over yAxis
        Utils.DrawStatus(yAxisBox.ctx, 'Good', yAxisBox.width - iconSize - marginLeft, yAxisBox.top, iconSize);
        Utils.DrawStatus(yAxisBox.ctx, 'Neutral', yAxisBox.width - iconSize - marginLeft, yAxisBox.top + yAxisBox.height / 2, iconSize);
        Utils.DrawStatus(yAxisBox.ctx, 'Bad', yAxisBox.width - iconSize - marginLeft, yAxisBox.top + yAxisBox.height, iconSize);
    },
};

function DualAnswerChartPlugin(avgAreaData) {
    this.avgAreaData = avgAreaData;

    return {
        beforeDraw: (chart, easing, options) => {
            let xAxisBox = chart.boxes.find(b => b.id === 'x-axis-0');
            let yAxisBox = chart.boxes.find(b => b.id === 'y-axis-0');

            yAxisBox.ctx.fillStyle = 'white';
            yAxisBox.ctx.fillRect(0, 0, yAxisBox.width - 15, yAxisBox.height);

            let chartArea = chart.chartArea;
            let chartBox = chart.boxes[0];
            let chartWidth = chartArea.right - xAxisBox.left;
            let chartHeight = chartArea.bottom - yAxisBox.top;

            // Draw gradient
            let grd = chartBox.ctx.createLinearGradient(chartArea.left, chartArea.bottom, chartArea.right, chartArea.top);
            grd.addColorStop(0, lightRed);
            grd.addColorStop(0.3, lightOrange);
            grd.addColorStop(1, lightGreen);

            chartBox.ctx.fillStyle = grd;
            chartBox.ctx.fillRect(chartArea.left, chartArea.top, chartWidth, chartHeight);

            // Draw average area
            if (this.avgAreaData) {
                drawArea(chart, xAxisBox, yAxisBox, this.avgAreaData);
            }

            // Draw text
            chartBox.ctx.strokeStyle = chartBox.ctx.fillStyle = chart.options.defaultFontColor;

            chartBox.ctx.font = chart.options.defaultFontSize + "px " + chart.options.defaultFontFamily;
            chartBox.ctx.textAlign = "left";
            chartBox.ctx.fillText("social animal", chartArea.left + 20, chartArea.top + 30);
            chartBox.ctx.fillText("critical/warning", chartArea.left + 20, chartArea.bottom - 20);
            chartBox.ctx.fillText("red flag", chartArea.right - chartWidth / 2 + 10, chartArea.bottom - chartHeight / 2 + 20);
            chartBox.ctx.textAlign = "right";
            chartBox.ctx.fillText("over-achiever", chartArea.right - 20, chartArea.bottom - 20);
            chartBox.ctx.fillText("contributor", chartArea.right - 20, chartArea.top + 30);
            chartBox.ctx.fillText("red flag", chartArea.left + chartWidth / 2 - 10, chartArea.top + chartHeight / 2 - 20);

            // Draw lines
            chartBox.ctx.strokeStyle = chart.options.defaultFontColor;
            chartBox.ctx.lineWidth = 1;

            chartBox.ctx.moveTo(chartArea.right - chartWidth / 2 + 30, chartArea.bottom - chartHeight / 2 + 30);
            chartBox.ctx.lineTo(chartArea.right - 50, chartArea.bottom - 40);
            chartBox.ctx.moveTo(chartArea.left + 40, chartArea.top + 40);
            chartBox.ctx.lineTo(chartArea.left + chartWidth / 2 - 50, chartArea.top + chartHeight / 2 - 40);
            chartBox.ctx.stroke();
        },
        afterDraw: (chart, easing, options) => {
            let iconSize = 15;
            let margin = iconSize / 2;

            let yAxisBox = chart.boxes.find(b => b.id === 'y-axis-0');
            let xAxisBox = chart.boxes.find(b => b.id === 'x-axis-0');

            // Draw status icons over yAxis
            Utils.DrawStatus(yAxisBox.ctx, 'Good', yAxisBox.width - iconSize - margin, yAxisBox.top, iconSize);
            Utils.DrawStatus(yAxisBox.ctx, 'Neutral', yAxisBox.width - iconSize - margin, yAxisBox.top + yAxisBox.height / 2, iconSize);
            Utils.DrawStatus(yAxisBox.ctx, 'Bad', yAxisBox.width - iconSize - margin, yAxisBox.top + yAxisBox.height, iconSize);

            // Draw status icons over xAxis
            Utils.DrawStatus(xAxisBox.ctx, 'Good', xAxisBox.left + xAxisBox.width, xAxisBox.top + iconSize + margin, iconSize);
            Utils.DrawStatus(xAxisBox.ctx, 'Neutral', xAxisBox.left + xAxisBox.width / 2, xAxisBox.top + iconSize + margin, iconSize);
            Utils.DrawStatus(xAxisBox.ctx, 'Bad', xAxisBox.left, xAxisBox.top + iconSize + margin, iconSize);
        }
    }
};

function drawArea(chart, xAxisBox, yAxisBox, dataPoints) {
    let chartArea = chart.chartArea;
    let chartBox = chart.boxes[0];
    let chartWidth = chartArea.right - xAxisBox.left;
    let chartHeight = chartArea.bottom - yAxisBox.top;
    let scale = 100;

    chartBox.ctx.lineWidth = 3;
    chartBox.ctx.strokeStyle = chartAreaColor;

    if (dataPoints.length === 0)
        return;

    let prevPoint = null;

    dataPoints.forEach((d, i) => {
        chartBox.ctx.beginPath();
        let ap = transPoint(d, chartArea.left, chartArea.bottom, scale, chartWidth, chartHeight);

        if (prevPoint != null) {
            let pp = transPoint(prevPoint, chartArea.left, chartArea.bottom, scale, chartWidth, chartHeight);
            chartBox.ctx.moveTo(pp.x, pp.y);
            chartBox.ctx.lineTo(ap.x, ap.y);
            chartBox.ctx.stroke();
        } else {
            chartBox.ctx.moveTo(ap.x, ap.y);
        }

        prevPoint = d;
    });

    let lp = transPoint(dataPoints[0], chartArea.left, chartArea.bottom, scale, chartWidth, chartHeight);
    chartBox.ctx.lineTo(lp.x, lp.y);
    chartBox.ctx.stroke();
}

function transPoint(point, xOffset, yOffset, scale, chartWidth, chartHeight) {
    return {
        x: xOffset + point.x / scale * chartWidth,
        y: yOffset - point.y / scale * chartHeight
    }
}

export default { PeriodicChartPlugin, DualAnswerChartPlugin };