import React from 'react';
import StatusIcon from '../StatusIcon';
import { ListItemTypes } from './TeamScoreList';

class TeamScoreListItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            index: this.props.index,
            score: this.props.score,
            listItemType: this.props.listItemType,
            teamId: (this.props.match.params && this.props.match.params.teamId),
        }
    }

    render() {

        let ListItem = (props) => <div {...props} className={"row justify-content-between " + this.state.listItemType.className} />;

        if (this.state.listItemType === ListItemTypes.link) {
            let Component = this.state.listItemType.component;
            ListItem = (props) => <Component {...props} className={"row justify-content-between " + this.state.listItemType.className} to={'/team/' + this.state.teamId + '/' + this.state.score.QuestionId} />;
        }

        return (
            <div className="list-group-item">
                <ListItem>
                    <div className="col-10">
                        <div className="row">
                            <p className="title col-12">{this.state.index}: {this.state.score.questionTitle}</p>
                        </div>
                        <div className="row">
                            <blockquote className="font-weight-light col-12" dangerouslySetInnerHTML={{ __html: this.state.score.questionText }} />
                        </div>
                    </div>
                    {this.state.score.score !== undefined && <StatusIcon className="col-2" status={this.state.score.score} />}
                </ListItem>
            </div>
        );
    }

}

export default TeamScoreListItem;