import React from 'react';
import { primaryColor } from '../../../scss/styles.scss';
import PeriodicAnswersChart from './chart/PeriodicAnswersChart';
import DualAnswerChart from './chart/DualAnswerChart';
import MIAService from '../../../services/MIA/MIAService';
import StatusIcon from '../../shared/StatusIcon';
import LoadIndicator from '../../shared/LoadIndicator';
import Utils from '../../shared/Utils';

class AnswerDetails extends React.Component {

    constructor(props) {
        super(props);

        let q = this._GetQuestion(this.props.questions, this.props.questionId);

        this.state = {
            teamId: this.props.teamId,
            teamName: this.props.teams.find(t => t.id === parseInt(this.props.teamId)).name,
            questionId: this.props.questionId,
            collectionId: q.Collection,
            teams: this.props.teams,
            question: q,
            questions: this.props.questions,
            questionText: q && q.QuestionText,
            answersByUser: null,
            individualAnswers: null,
            periodicDataSet: null,
            avgAreaData: null,
            loadDualAnswerChart: false
        };
    }

    componentDidMount() {
        this._LoadIndividualAnswers();
        this._LoadPeriodicalAnswers();

        if (this.state.question.Collection)
            this.setState({ loadDualAnswerChart: true });
        else
            this.setState({ loadDualAnswerChart: false });
    }

    componentDidUpdate(prevProps, prevState) {
        // Update state if teamId or questionId changes
        if (prevProps.teamId !== this.props.teamId || prevProps.questionId !== this.props.questionId)
        {
            let q = this._GetQuestion(this.props.questions, this.props.questionId);

            this.setState({
                teamId: this.props.teamId,
                teams: this.props.teams,
                teamName: this.props.teams.find(t => t.id === parseInt(this.props.teamId)).name,
                questionId: this.props.questionId,
                collectionId: q.Collection,
                question: q,
                questions: this.props.questions,
                questionText: q && q.QuestionText,
                answersByUser: null,
                individualAnswers: null,
                periodicDataSet: null,
                avgAreaData: null,
                loadDualAnswerChart: false
            });
        }

        // Load new result if teamId or questionId changes
        if (prevState.teamId !== this.state.teamId || prevState.questionId !== this.state.questionId) {
            this._LoadIndividualAnswers();
            this._LoadPeriodicalAnswers();

            if (this.state.question.Collection)
                this.setState({ loadDualAnswerChart: true });
            else
                this.setState({ loadDualAnswerChart: false });
        }
    }

    render() {
        return (
            <div>
                {this.state.questionText &&
                    <div className="row">
                        <div className="col-12">
                            <blockquote className="blockquote" dangerouslySetInnerHTML={{ __html: this.state.questionText }} />
                        </div>
                    </div>}

                <div className="row justify-content-between">
                    <div className="col-lg-5 col-md-6">
                        <div className="list-group list-group-flush">
                            {this.state.individualAnswers ? (
                                this.state.individualAnswers.map((answer, index) => {
                                    return (
                                        <div key={index} className="list-group-item">
                                            <div className="row">
                                                <div className="title col-9">
                                                    <p>{answer.UserName}</p>
                                                </div>
                                                <div className="col-3">
                                                    {answer.Score ?
                                                        <StatusIcon status={answer.Score} /> :
                                                        <p>No answer</p>}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                    <LoadIndicator />
                                )}
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6">
                        {this.state.periodicDataSet ?
                            (this.state.periodicDataSet.datasets[0].data.length === 0 ? (
                                <p className="alert alert-primary">There are no results for this team yet.</p>
                            ) : (
                                    <div>
                                        <p>Answers over time</p>
                                        <PeriodicAnswersChart dataSets={this.state.periodicDataSet} legend={{ display: true }} />
                                    </div>
                                )
                            ) : (
                                <LoadIndicator />
                            )}
                    </div>
                </div >
                {this.state.loadDualAnswerChart && (
                    <div>
                        <hr />
                        <DualAnswerChart
                            showIndividualData={true}
                            questions={this.state.questions}
                            teams={this.state.teams}
                            teamId={this.state.teamId}
                            teamName={this.state.teamName}
                            collectionId={this.state.collectionId}
                            questionId={this.state.questionId} />
                    </div>
                )}
            </div >
        );
    }

    _LoadIndividualAnswers() {
        MIAService.getInstance().getReport('f7beecc6-3fcc-4613-a1c3-43f2a39e4d5a', { teamId: this.state.teamId, questionId: this.state.questionId })
            .then(resp => resp.json())
            .then(result => {
                // Capitalize user name
                let answers = result.answers.map(a => {
                    a.UserName = Utils.CapitalizeString(a.UserName);
                    return a;
                });

                this.setState({
                    individualAnswers: answers,
                });
            });
    }

    _LoadPeriodicalAnswers() {
        MIAService.getInstance().getReport('all-time-averages-question-v2', { teamId: this.state.teamId, questionId: this.state.questionId })
            .then(resp => resp.json())
            .then(result => {
                let periodicData = [];
                let avgData = [];

                result.answers.forEach((a, i) => {
                    avgData.push({
                        x: i + 1,
                        y: a.TeamAverage,
                    })
                    periodicData.push({
                        x: i + 1,
                        y: a.Score,
                    })
                });

                let labels = [];

                for (let i = 1; i <= periodicData.length; i++)
                    labels.push(i);

                let periodicDataSets = {
                    labels: labels,
                    datasets: [{
                        label: this.state.teamName,
                        showLine: true,
                        borderColor: primaryColor,
                        pointBackgroundColor: primaryColor,
                        tension: 0,
                        fill: false,
                        data: periodicData,
                    }, {
                        label: 'Average',
                        showLine: true,
                        borderColor: 'lightgray',
                        pointBackgroundColor: 'lightgray',
                        tension: 0,
                        fill: false,
                        data: avgData,
                    }]
                };

                this.setState({
                    periodicDataSet: periodicDataSets,
                });
            });

    }

    _GetQuestion(questions, questionId) {
        return questions.find(q => q.Id === parseInt(questionId));
    }

}

export default AnswerDetails;