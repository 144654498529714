import React from 'react';
import { Link } from "react-router-dom";
import ErasmusLogo from '../../../../assets/erasmus-uni-logo.png';

function EndMessage() {
    return (
        <div id="About" className="row">
            <div className="col-12">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <img src={ErasmusLogo} width="100px" className="img-thumbnail" alt="Erasmus University Logo" />
                        <p>Thank you for your participation.</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <Link className="btn btn-primary btn-lg float-right" to="/team-dashboard">Team Dashboard</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EndMessage;