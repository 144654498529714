import React from 'react';
import Routes from '../../routes/Routes';

function NavbarTitle(props) {

    let route = Routes.find(r => r.path === ((props.match && props.match.path) || props.route.path));

    if (route) {
        if (route.id === 'maintenance')
            return null;

        let title = route.title;
        if (route.id === 'assignment-question' && props.match.params.questionIndex && props.match.params.totalNumberOfQuestions && props.match.params.questionIndex === props.match.params.totalNumberOfQuestions)
            title = 'Thanks';
        return <span>{title}</span>;
    }
    return null;
}

export default NavbarTitle;