import React from 'react';
import LoadIndicator from '../shared/LoadIndicator';
import ErrorMessage from '../shared/ErrorMessage';

import { Link } from 'react-router-dom';
import MIAService from '../../services/MIA/MIAService';

class ResetPassword extends React.Component {

    generalError = "We're sorry, an error occured. Please, try again later.";
    initialFormState = "col-md-8 col-lg-5 needs-validation";

    constructor(props) {
        super(props);

        this.state = {
            validationToken: props.match.params.validationToken,
            formClass: this.initialFormState,
            newPassword: '',
            passwordReEntry: '',
            passwordChanged: false,
            invalidatePasswordReEntry: false,
            error: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ formClass: this.initialFormState + ' was-validated' })

        let invalidatePasswordReEntry = this.state.newPassword !== this.state.passwordReEntry;
        if (invalidatePasswordReEntry)
            this.setState({ formClass: this.initialFormState, invalidatePasswordReEntry: invalidatePasswordReEntry })

        if (invalidatePasswordReEntry || !event.target.checkValidity())
            return;

        this.setState({ processing: true });

        MIAService.getInstance().resetPassword(this.state.validationToken, this.state.newPassword)
            .then(resp => resp.ok ? resp.status : resp.json())
            .then(resp => {
                if (resp === 200) {
                    this.setState({ passwordChanged: true, processing: false });
                    return;
                }

                if (resp.Message) {
                    if (resp.Message === "Token already used")
                        throw new Error("You have already reset your password.");
                    else if (resp.Message === "Token is expired")
                        throw new Error("The request to change your password has expired.");
                }

                throw new Error(this.generalError);
            })
            .catch(error => this.setState({ error: error.message, formClass: this.initialFormState, processing: false }));
    }

    render() {
        return (
            <div className="row justify-content-center">
                {this.state.passwordChanged ? (
                    <div className="col-5">
                        <p className="alert alert-primary">Your password has been successfully changed.</p>
                        <Link className="btn btn-primary btn-lg" to="/">Back to login</Link>
                    </div>
                ) :
                    (!this.state.processing ?
                        <form className={this.state.formClass} onSubmit={this.handleSubmit} noValidate>
                            <p>Enter your new password below:</p>

                            <ErrorMessage error={this.state.error} link={this.state.error !== this.generalError && <Link to='/forgot-password'>Request another reset link.</Link>} />

                            {/* Password */}
                            <div className="form-group">
                                <input name="newPassword" type="password" className="form-control form-control-lg" placeholder="New password" onChange={this.handleChange} value={this.state.newPassword} minLength="6" required />
                                <div className="invalid-feedback">
                                    Provide a password with a length of at least 6 characters.
                                </div>
                            </div>

                            {/* Confirm Password */}
                            <div className="form-group">
                                <input name="passwordReEntry" type="password" className={this.state.invalidatePasswordReEntry ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"} placeholder="Re-enter new password" onChange={this.handleChange} value={this.state.passwordReEntry} minLength="6" required />
                                <div className="invalid-feedback">
                                    Password does not match the one above.
                                </div>
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary btn-lg">Change password</button>
                            </div>
                        </form> :
                        <LoadIndicator />)}
            </div>
        );
    }
}

export default ResetPassword;