import React from 'react';
import { TeamScoreList, ListItemTypes } from '../../shared/team-score/TeamScoreList';

import 'bootstrap/js/src/dropdown';

class Answers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            teamId: this.props.teamId,
            teams: this.props.teams,
        }
    }

    render() {
        return (
            <div>
                <TeamScoreList listItemType={ListItemTypes.link} teamId={this.state.teamId} />
            </div>
        );
    }

}

export default Answers;