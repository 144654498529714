import React from 'react';
import { Redirect, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import LoadIndicator from '../shared/LoadIndicator';
import MIAService from '../../services/MIA/MIAService';
import ErrorMessage from '../shared/ErrorMessage';
import InstructionToggle from '../shared/instructions/InstructionToggle';

class Login extends React.Component {

  initialFormState = 'col-md-8 col-lg-5 needs-validation';

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      rememberMe: true,
      processing: true,
      formClass: this.initialFormState,
      loginError: null,
      redirect: null,
      from: this.props.location.state && this.props.location.state.from.pathname,
      authToken: this.props.match.params.token,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (MIAService.getInstance().isAuthenticated()) {
      this._determineRedirect();
      return;
    }

    let refreshToken = this.state.authToken;

    if (refreshToken === undefined)
      refreshToken = MIAService.getInstance().getRefreshToken();

    let accessToken = MIAService.getInstance().getAccessToken();

    if (refreshToken || accessToken) {
      MIAService.getInstance().loginWithToken(refreshToken, accessToken)
        .then(resp => {
          if (resp.ok) {
            this._determineRedirect();
            return;
          }

          if (resp.status >= 400)
            throw new Error("Session expired, log in with your username and password or use the link as provided in your mail.");
        })
        .catch(error => {
          this.setState({ loginError: error.message, processing: false, formClass: this.initialFormState });
        });
    }
    else
      this.setState({ processing: false });
  }

  handleChange(event) {
    let target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let id = target.id;

    this.setState({ [id]: value, validated: false });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ formClass: this.initialFormState + ' was-validated' })

    // Stop execution if there are form validation errors
    if (!event.target.checkValidity())
      return;

    this.setState({ processing: true });

    MIAService.getInstance().login(this.state.username, this.state.password, this.state.rememberMe)
      .then(resp => {
        if (resp.error) {
          if (resp.error === 'invalid_grant') {
            throw new Error("Username or password is incorrect.");
          } else if (resp.error === 'no_course') {
            throw new Error("Registration hasn't been completed.");
          }
          return;
        }

        this._determineRedirect();
      })
      .catch(error => {
        this.setState({ loginError: error.message, processing: false, formClass: this.initialFormState });
      });
  }

  renderLoginForm() {
    return (
      <div className="row justify-content-center">
        <form className={this.state.formClass} onSubmit={this.handleSubmit} noValidate>

          <ErrorMessage error={this.state.loginError} />

          {/* User */}
          <div className="form-group row">
            <label htmlFor="user" className="col-sm-3 col-form-label col-form-label-lg">Username:</label>
            <div className="input-group col-sm-9">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <FontAwesomeIcon icon={faUser} />
                </div>
              </div>
              <input id="username" type="email" className="form-control form-control-lg" placeholder="username" onChange={this.handleChange} value={this.state.username} required />
              <div className="invalid-feedback">
                Please provide a valid email address.
              </div>
            </div>
          </div>

          {/* Password */}
          <div className="form-group row">
            <label htmlFor="password" className="col-sm-3 col-form-label col-form-label-lg">Password:</label>
            <div className="input-group col-sm-9">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <FontAwesomeIcon icon={faLock} />
                </div>
              </div>
              <input id="password" type="password" className="form-control form-control-lg" placeholder="password" onChange={this.handleChange} value={this.state.password} required />
              <div className="invalid-feedback">
                Please fill in a password.
            </div>
            </div>
          </div>

          {/* Remember me */}
          <div className="form-group row">
            <div className="col">
              <div className="form-check">
                <input type="checkbox" className="form-check-input" id="rememberMe" checked={this.state.rememberMe} onChange={this.handleChange} />
                <label className="form-check-label" htmlFor="rembemerMe">Remember me</label>
              </div>
            </div>
            <div className="col">
              <div className="float-right">
                <InstructionToggle description={"Log-in using your email address and the password which is send to you. If you're a student, click the smart link you received in your email. Emails will be send by oncourse@organiq.nl."} />
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="row">
            <div className="col-6">
              <button type="submit" className="btn btn-primary btn-lg">Login</button>
            </div>
            <div className="col-6">
              <Link id="forgot-password-link" to="/forgot-password">Forgot password</Link>
            </div>
          </div>
        </form>
      </div>
    )
  }

  render() {
    if (this.state.redirect)
      return this.state.redirect;

    if (this.state.processing)
      return (
        <div className="row justify-content-center">
          <div className="col-auto">
            <p>Verifying..</p>
            <LoadIndicator />
          </div>
        </div>
      );

    return this.renderLoginForm();
  }

  async _determineRedirect() {
    if (MIAService.getInstance().userSession.groups === null) {
      await MIAService.getInstance().getGroups()
        .then(resp => resp.json())
        .then(res => {
          if (res.length === 0) {
            this.setState({ processing: false, loginError: "You are not assigned to a team yet." });
            MIAService.getInstance().logout(false);
            return;
          }

          MIAService.getInstance().userSession.groups = res.flatMap(course => course.groups);
        })
        .catch(error => {
          MIAService.getInstance().logout(false);
          this.setState({ loginError: error.message, processing: false, formClass: this.initialFormState });
        });
    }
    if (MIAService.getInstance().userSession.accountId === null) {
      await MIAService.getInstance().getProfile()
        .then(req => req.json())
        .then(res => {
          MIAService.getInstance().userSession.accountId = res.id;
        })
        .catch(error => {
          MIAService.getInstance().logout(false);
          this.setState({ loginError: error.message, processing: false, formClass: this.initialFormState });
        });
    }

    if (process.env.REACT_APP_IS_PRODUCTION === 'false')
      console.log(MIAService.getInstance().userSession);

    // Get first activity and redirect to assignment
    if (MIAService.getInstance().userSession.includesRoles(['Learner'])) {
      if (MIAService.getInstance().userSession.activity === null) {
        await MIAService.getInstance().getActivities()
          .then(resp => resp.json())
          .then(activities => {
            if (activities.length > 0)
              MIAService.getInstance().userSession.activity = activities[0];
          })
          .catch(error => {
            this.setState({ loginError: error.message, processing: false, formClass: this.initialFormState });
          });
      }

      if (this.state.from) {
        this.setState({ redirect: <Redirect to={this.state.from} /> });
        return;
      }

      if (MIAService.getInstance().userSession.activity) {
        this.setState({ redirect: <Redirect to={"/about/" + MIAService.getInstance().userSession.activity.assignmentCode} /> });
      } else {
        this.setState({ redirect: <Redirect to="/team-dashboard" /> });
      }
    }

    // Redirect to teams overview for teacher
    if (MIAService.getInstance().userSession.includesRoles(['Instructor', 'Mentor'])) {
      this.setState({ redirect: <Redirect to="/teams" /> });
    } else {
      // Other roles are not supported
      this.setState({ processing: false, loginError: "No active subscription." });
      MIAService.getInstance().logout(false);
    }
  }

}

export default Login;