import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./scss/styles.scss";

import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    Sentry.init({
        dsn: "https://8dc3870640174e60866d78382af7ffc6@sentry.organiq.nl/17",
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
