import React from "react";
import StatusIcon from "../../shared/StatusIcon";
import { Link } from "react-router-dom";
import LoadIndicator from "../../shared/LoadIndicator";
import MIAService from "../../../services/MIA/MIAService";
import ErrorMessage from "../../shared/ErrorMessage";

class Teams extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      averages: null,
      onTrackScores: null,
      responseRates: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    let averages = [];

    // Load normal averages
    let normalAverages = await MIAService.getInstance()
      .getReport("a0fa9ffd-3aae-4433-9904-04e40d02c0e4")
      .then((req) => req.ok && req.json())
      .then((res) => {
        return res.responseRates;
      })
      .catch((error) =>
        this.setState({ loading: false, error: error.message })
      );

    // Load weighted averages
    let weightedAverages = await MIAService.getInstance()
      .getReport("61a3f88d-6e76-4f83-8c73-9e5c698d2176")
      .then((req) => req.ok && req.json())
      .then((res) => {
        return res.averages;
      })
      .catch((error) =>
        this.setState({ loading: false, error: error.message })
      );

    // TODO: optimize by combining the queries of the two requests above
    // Replace normal averages with any weighted averages
    if (weightedAverages && weightedAverages.length > 0) {
      normalAverages.forEach(a => {
        weightedAverages.forEach(w => {
          let n = a;
          if (a.TeamId === w.TeamId) {
            n.Score = w.Score;
            averages.push(n);
          }
        });
      });
    } else {
      averages = normalAverages;
    }

    // Load off-track indication
    await MIAService.getInstance()
      .getReport("b76d81bc-a847-4db2-bd56-6bdcd306b557")
      .then((req) => req.ok && req.json())
      .then((res) => {
        this.setState({ onTrackScores: res.scores });
      })
      .catch((error) =>
        this.setState({ loading: false, error: error.message })
      );

    // Load response rate for mentors
    await MIAService.getInstance()
      .getReport("e4763f92-6981-480d-b529-e66a8f8e75c8")
      .then((req) => req.ok && req.json())
      .then((res) => {
        this.setState({ responseRates: res.responseRates });
      })
      .catch((error) =>
        this.setState({ loading: false, error: error.message })
      );

    this.setState({ loading: false, averages: averages });
  }

  render() {
    return (
      <div id="team-overview" className="row">
        <div className="col justify-content-center">
          {/* Show error message */}
          {this.state.error && (
            <div className="row justify-content-center">
              <ErrorMessage error={this.state.error} />
            </div>
          )}

          {/* Show no result message */}
          {this.state.averages && this.state.averages.length === 0 && (
            <div className="row">
              <div className="col text-center">
                There are no results yet.
              </div>
            </div>
          )}
          
          {this.state.loading && <LoadIndicator />}

          <div className="row">
          {/* Show teams */}
            {!this.state.loading && this.state.averages && (
              this.state.averages.map((avg, index) => (
                <div key={index} className="col-sm-6 col-lg-3">
                  <div className="card mt-3">
                    <div className="card-body row">
                      <div className="col-12">
                        <h3>{avg.TeamName}</h3>
                        <div className="row">
                          <div className="col-9">Team health:</div>
                          <div className="col-3">
                            <StatusIcon status={avg.Score} />
                          </div>
                        </div>
                        {this._RenderOnTrackScore(
                          this.state.onTrackScores,
                          avg.TeamId
                        )}
                        {this.state.responseRates &&
                          this._RenderResponseRate(
                            this.state.responseRates,
                            avg.TeamId
                          )}
                        <div className="row">
                          <div className="col-12 mt-3">
                            <Link
                              className="btn btn-primary btn-lg"
                              to={"/team/" + avg.TeamId}
                            >
                              {" "}
                              Show results{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    );
  }

  _RenderResponseRate(responseRates, teamId) {
    let rr = responseRates.find((s) => s.TeamId === teamId);

    if (rr)
      return (
        <div className="row justify-content-between">
          <div className="col-8">Response rate:</div>
          <div className="col-4">
            <p className="text-right">{rr.RespRate}%</p>
          </div>
        </div>
      );
    return null;
  }

  _RenderOnTrackScore(scores, teamId) {
    let s = scores.find((s) => s.TeamId === teamId);

    if (s)
      return (
        <div className="row">
          <div className="col-9">On track:</div>
          <div className="col-3">
            <StatusIcon status={s.Score} />
          </div>
        </div>
      );
    return null;
  }
}

export default Teams;
