import React from 'react';
import { Redirect } from 'react-router-dom';

import LoadIndicator from '../../../shared/LoadIndicator';
import Question from '../assignment/Question';
import EndMessage from '../assignment/EndMessage';
import MIAService from '../../../../services/MIA/MIAService';
import ErrorMessage from '../../../shared/ErrorMessage';

class Assignment extends React.Component {

    constructor(props) {
        super(props);

        this.assignmentQuestions = null;
        this.assignmentSession = null;
        this.answers = [];

        let questionIndex = this._parseQuestionIndex(this.props.match.params.questionIndex);

        this.state = {
            code: this.props.match.params.code,
            questionIndex: questionIndex,
            totalNumberOfQuestions: 0,
            loading: true,
            question: null,
            answer: null,
            error: null,
        }

        this.onAnswer = this.onAnswer.bind(this);
    }

    componentDidUpdate(prevProps) {
        let questionIndex = this._parseQuestionIndex(this.props.match.params.questionIndex);
        if (this.assignmentQuestions && prevProps.match.params.questionIndex !== this.props.match.params.questionIndex) {
            if (this.assignmentSession && this.assignmentSession.status === 'finished')
                return;

            let question = this._getQuestion(questionIndex);

            this.setState({ loading: true, error: null, question: question, questionIndex: questionIndex, answer: (question && this._getFilledInAnswer(question.id)) });

            this._updateAssignmentSessionPosition(questionIndex)
                .then(resp => this.setState({ loading: false }));
        }
    }

    componentDidMount() {
        this._init();
    }

    render() {
        return (
            <div >
                {
                    this.state.error ? (
                        <ErrorMessage error={this.state.error} />
                    ) : (this.state.loading ? (
                        <LoadIndicator />
                    ) : (
                            (this.state.questionIndex < this.state.totalNumberOfQuestions ?
                                <Question questionData={this.state.question} answer={this.state.answer} answerCallback={this.onAnswer} /> :
                                <EndMessage />)
                        )
                    )
                }
            </div>
        );
    }

    onAnswer = async (answer) => {
        if (!this.state.loading)
            this.setState({ loading: true });

        let newSessionPosition = this.state.questionIndex + 1;

        // Submit answer

        if (this.state.question.moduleName !== 'TextModule' && newSessionPosition <= this.assignmentQuestions.length) {
            let sessionAnswerStatus = await MIAService.getInstance().submitAssignmentSessionAnswers(this.state.code, this.assignmentSession.id, answer)
                .then(resp => resp.status)
                .catch(error => {
                    console.log(error);
                    this.setState({ error: error.message, loading: false });
                });

            if (sessionAnswerStatus !== 200) {
                this._showError(sessionAnswerStatus);
                return;
            }
        }

        // Add or update the answer for already loaded answers

        if (answer) {
            if (this._getFilledInAnswer(answer.questionId))
                this.answers[this._getAnswerIndex(answer.questionId)] = answer;
            else
                this.answers.push(answer);
        }

        // Update the state and go to the next question

        this.assignmentSession.position = newSessionPosition;

        this.setState({ error: null, loading: false });
        this.props.history.push("/assignment/" + this.state.code + "/" + newSessionPosition + '/' + this.state.totalNumberOfQuestions);
    }

    _init = async () => {
        if (!MIAService.getInstance().userSession.activity) {
            this.setState({ loading: false, error: "This survey is not scheduled at the moment." });
            return null;
        }

        // Load assignment questions
        let assignmentResponse = await MIAService.getInstance().getAssignment(this.state.code)
            .then(resp => resp.ok ? resp.json() : resp.status)
            .then(resp => {
                if (resp.assignmentQuestions)
                    return resp.assignmentQuestions;
                return resp;
            })
            .catch(error => {
                console.log(error);
                this.setState({ error: error.message, loading: false });
                return;
            });

        if (assignmentResponse === 404) {
            this._showError(assignmentResponse);
            return;
        }

        this.assignmentQuestions = assignmentResponse;

        // Get assignment session of activity
        this.assignmentSession = await MIAService.getInstance().getActivitySession(MIAService.getInstance().userSession.activity.id)
            .then(resp => resp.ok ? resp.json() : null);

        // Retrieve session answers
        this.answers = await MIAService.getInstance().getAssignmentSessionAnswers(this.state.code, this.assignmentSession.id)
            .then(resp => resp.json());

        // questionIndex may not be higher then the number of questions of a assignment
        let questionIndex = this.state.questionIndex >= this.assignmentQuestions.length ? this.assignmentQuestions.length - 1 : this.state.questionIndex;

        // questionIndex may not be higher then the index of the next question to be answered
        if (questionIndex > this.answers.length)
            questionIndex = this.answers.length;

        // Assignment session is finished
        let finished = this.assignmentSession.status === 'finished';

        if (finished)
            questionIndex = this.assignmentQuestions.length;

        let question = !finished ? this.assignmentQuestions[questionIndex].question : null;

        // Fix path if params are incorrect
        if (questionIndex !== this.props.match.params.questionIndex || this.assignmentQuestions.length !== this.props.match.params.totalNumberOfQuestions)
            this.props.history.replace("/assignment/" + this.state.code + '/' + questionIndex + '/' + this.assignmentQuestions.length);

        this.setState({
            loading: false,
            questionIndex: questionIndex,
            totalNumberOfQuestions: this.assignmentQuestions.length,
            question: question,
            answer: !finished ? this._getFilledInAnswer(question.id) : null,
        });
    }

    _getFilledInAnswer(questionId) {
        return this.answers.find((a) => a.questionId === questionId);
    }

    _getAnswerIndex(questionId) {
        return this.answers.findIndex((a) => a.questionId === questionId);
    }

    _updateAssignmentSessionPosition(position) {
        return MIAService.getInstance().submitAssignmentSessionPosition(this.state.code, this.assignmentSession.id, position)
            .then(resp => {
                if (position >= this.assignmentQuestions.length) {
                    this.assignmentSession.status = 'finished';
                    MIAService.getInstance().finishAssignmentSession(this.state.code, this.assignmentSession.id);
                    MIAService.getInstance().userSession.activity = null;
                }
                return resp.status;
            });
    }

    _getQuestion(questionIndex) {
        return questionIndex < this.assignmentQuestions.length ? this.assignmentQuestions[questionIndex].question : null;
    }

    _parseQuestionIndex(index) {
        if (typeof index !== 'number')
            index = parseInt(index);

        if (index === undefined || isNaN(index) || index < 0)
            return 0;

        return index;
    }

    _showError(responseCode) {
        let errorMsg = 'Something went wrong, please try again later.';

        if (responseCode < 1)
            errorMsg = 'Please check your internet connection and try again.';
        else if (responseCode === 404)
            errorMsg = <Redirect to="/page-not-found" />;
        else if (responseCode > 500)
            errorMsg = 'Please check your internet connection and try again.';

        this.setState({ loading: false, error: errorMsg });
    }

}

export default Assignment;