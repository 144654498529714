import React from 'react';
import { Link } from 'react-router-dom';
import Utils from '../../shared/Utils';

class TeamQuestionControls extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            teams: this.props.teams,
            teamId: this.props.teamId,
            questions: this.props.questions,
            questionId: this.props.questionId,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.teamId !== prevProps.teamId || this.props.questionId !== prevProps.questionId) {
            let newState = {};
            newState.teamId = this.props.teamId;

            if (this.props.questionId !== prevProps.questionId)
                newState.questionId = this.props.questionId;

            this.setState(newState);
        }
    }

    render() {
        let selectedQuestion = this._GetQuestionFromId(this.state.questionId);

        return (
            <div id="team-question-controls" className="btn-group">
                {/* Team dropdown */}
                {this.state.teams && this.state.teamId !== undefined && (
                    <div className="btn-group">
                        <button type="button" className="btn btn-lg btn-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this._GetTeamNameFromId(this.state.teamId)}</button>
                        <div className="dropdown-menu">
                            {this.state.teams.map((team, index) => {
                                let path = '/team/' + team.id;
                                if (this.state.questionId !== undefined)
                                    path += ('/' + this.state.questionId);

                                return <Link key={index} className="dropdown-item" to={path}>{team.name}</Link>
                            })}
                        </div>
                    </div>
                )}
                {/* Question dropdown */}
                {this.state.questions && this.state.questionId !== undefined && (
                    <div className="btn-group">
                        <button type="button" className="btn btn-lg btn-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{selectedQuestion.Title}</button>
                        <div className="dropdown-menu">
                            {this.state.questions.map((q, index) =>
                                <Link key={index} className="dropdown-item" to={'/team/' + this.state.teamId + '/' + q.Id}>{q.Title}</Link>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    _GetTeamNameFromId(teamId) {
        let team = this.state.teams.find(t => t.id === parseInt(teamId));
        return (team && team.name);
    }

    _GetQuestionFromId(questionId) {
        if (Utils.IsInt(questionId))
            questionId = parseInt(questionId);
        return this.state.questions.find(q => q.Id === questionId);
    }

}

export default TeamQuestionControls;