import React from 'react';
import Answers from "./Answers";
import TeamQuestionControls from './TeamQuestionControls';
import AnswerDetails from './AnswerDetails';
import MIAService from '../../../services/MIA/MIAService';
import LoadIndicator from '../../shared/LoadIndicator';
import ErrorMessage from '../../shared/ErrorMessage';
import TeamOverview from './TeamOverview';
import TeamReport from '../student/TeamReport';
import Utils from '../../shared/Utils';

class TeamAnswerDashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            teamId: this.props.match.params.teamId,
            teams: MIAService.getInstance().userSession.groups,
            questionId: this.props.match.params.questionId,
            questions: null,
            error: null,
        }
    }

    componentDidMount() {
        this._LoadQuestions();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.teamId !== prevProps.match.params.teamId || this.props.match.params.questionId !== prevProps.match.params.questionId) {
            let newState = { teamId: this.props.match.params.teamId };
            if (this.props.match.params.questionId !== prevProps.match.params.questionId)
                newState.questionId = this.props.match.params.questionId;

            this.setState(newState);
        }

        if (this.state.teamId !== prevState.teamId)
            this._LoadQuestions();
    }

    render() {
        if (this.state.error) return <ErrorMessage error={this.state.error} />;
        return (
            this.state.questions ?
                (<div>
                    <hr />
                    <TeamQuestionControls teams={this.state.teams} questions={this.state.questions} teamId={this.state.teamId} questionId={this.state.questionId} />
                    <hr />
                    {this._ShowSubPage()}
                </div>) :
                <LoadIndicator />
        );
    }

    _ShowSubPage() {
        if (this.state.teamId && this.state.questionId === undefined)
            return <Answers teams={this.state.teams} teamId={this.state.teamId} />;
        if (this.state.teamId && this.state.questionId && Utils.IsInt(this.state.questionId))
            return <AnswerDetails
                    teams={this.state.teams}
                    teamId={this.state.teamId}
                    questionId={this.state.questionId}
                    questions={this.state.questions}
            />;
        if (this.state.teamId && this.state.questionId && this.state.questionId === 'overview')
            return <TeamOverview
                teamId={this.state.teamId}
                teamName={this._GetTeamNameFromId(this.state.teamId)}
                questions={this.state.questions}
            />;
        if (this.state.teamId && this.state.questionId && this.state.questionId === 'team-report')
            return <TeamReport
                teamId={this.state.teamId}
                teamName={this._GetTeamNameFromId(this.state.teamId)}
                questions={this.state.questions}
            />;
    }

    _LoadQuestions() {
        if (this.state.questions !== null)
            this.setState({ questions: null });

        MIAService.getInstance().getReport('92dcf443-24ad-4080-88d8-e63ef7aa6909', { teamId: this.state.teamId })
            .then(resp => resp.ok ? resp.json() : resp.status)
            .then(resp => {
                if (resp.Questions) {
                    resp.Questions.map((q, i) => {
                        q.Title = (i+1) + ': ' + q.Title;
                        return q;
                    });

                    resp.Questions.unshift({ Id: 'team-report', Title: 'Team Report' });
                    resp.Questions.unshift({ Id: 'overview', Title: 'Overview' });
                    this.setState({ questions: resp.Questions });
                }
                return resp;
            })
            .catch(error => this.setState({ error: error.message }));
    }

     _GetTeamNameFromId(teamId) {
        let team = this.state.teams.find(t => t.id === parseInt(teamId));
        return (team && team.name);
    }
   
}

export default TeamAnswerDashboard;