import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import MIAService from '../../services/MIA/MIAService';

function PrivateRoute({ component: Component, ...rest }) {

    let authenticated = MIAService.getInstance().isAuthenticated();
    let validRole = authenticated && MIAService.getInstance().userSession.includesRoles(rest.context.roles);

    return (
        <Route
            {...rest}
            render={props =>
                (authenticated && validRole) ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRoute;