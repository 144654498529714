import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

class LogoutButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = { authenticated: this.props.authenticated }
    }

    render() {
        if (this.state.authenticated)
            return <Link id="logoutButton" className="btn btn-secondary" to="/logout"><FontAwesomeIcon icon={faSignOutAlt} /> Logout</Link>;

        return null;
    }

}

export default LogoutButton;