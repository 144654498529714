export default class UserSession {

    constructor() {
        // Used to make authorized requests within a session
        this.accessToken = null;

        // The token stored in order to refresh the accessToken
        this.refreshToken = null;
        
        this.accountId = null;
        this.activeCourse = null;
        this.groups = null;
        this.activity = null;
    }

    includesRoles(roles) {
        let included = false;
        this.groups.forEach(function (g) {
            g.roles.forEach(function (role) {
                if (roles.includes(role))
                    included = true;
            });
        });
        return included;
    }

}