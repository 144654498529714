import React from 'react';
import AssignmentControls from '../AssignmentControls';

class TextModule extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            htmlText: this.props.data.text,
            answerCallback: this.props.answerCallback,
        }

        this.validate = this.validate.bind(this);
    }

    validate() {
        this.state.answerCallback();
    }

    render() {
        return (
            <form onSubmit={this.validate}>
                <div className="row justify-content-center">
                    <div className="col-12" dangerouslySetInnerHTML={{ __html: this.state.htmlText }}></div>
                </div>

                <AssignmentControls />
            </form>
        );
    }

}

export default TextModule;