import React from 'react';
import { Bubble } from 'react-chartjs-3';
import IconBenchmark from '../../../../assets/icon-benchmark.svg';
import { primaryColor } from '../../../../scss/styles.scss';
import ChartPlugins from './ChartPlugins';
import InstructionToggle from '../../../shared/instructions/InstructionToggle';
import convexHull from 'convexhull-js';
import Utils from '../../../shared/Utils';
import MIAService from '../../../../services/MIA/MIAService';
import StatusIcon from '../../../shared/StatusIcon';
import LoadIndicator from '../../../shared/LoadIndicator';

class DualAnswerChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showIndividualData: this.props.showIndividualData,
            teamId: this.props.teamId,
            teamName: this.props.teamName,
            questionId: this.props.questionId,
            collectionId: this.props.collectionId,
            questions: this.props.questions,
            avgAreaData: null,
            chartOptions: null,

            dualAnswerDataLoaded: false,
            chartData: null,
        }
    }

    componentWillUnmount() {
        this.setState({
            teamId: null,
            questionId: null,
            teamName: null,
            collectionId: null,
            questions: null,
            dualAnswerDataLoaded: false,
            chartData: null,
            avgAreaData: null,
        });
    }

    componentDidMount() {
        this._LoadDualAnswers();
    }

    render() {
        if (!this.state.chartData)
            return null;
        
        if (!this.state.dualAnswerDataLoaded)
            return <LoadIndicator />;

        return (
            <div className="row justify-content-between">
                {this.state.showIndividualData &&
                    <div className="col-lg">
                        <div className="list-group list-group-flush">
                            {this.state.answersByUser.map((answers, index) => {
                                return (
                                    <div key={index} className="list-group-item">
                                        <p className="title">{answers[0].Name}</p>

                                        <div className="list-group list-group-flush">
                                            {answers.map((a, index) =>
                                                <div key={index} className="list-group-item">
                                                    <div className="row">
                                                        <div className="col-9">
                                                            <p>Q: {Utils.StripHTMLFromString(a.QuestionTitle)}</p>
                                                        </div>
                                                        <div className="col-3">
                                                            {a.Answer ?
                                                                <StatusIcon status={a.Answer} /> :
                                                                <p>No answer</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>}
                <div className="col-lg">
                    <div className="bubbleChart">
                        <div className="bubble-chart-legend d-flex p-3 ml-5 justify-content-around align-items-end">
                            <div className="mt-3">
                                <img className="m-0 mr-2 ml-4" src={IconBenchmark} height="35em" alt="icon benchmark" />
                                <strong>Benchmark scores other teams</strong>
                            </div>
                            <InstructionToggle
                                description="In this figure we distinguish two types of ‘red flags’:
                                    <ol>
                                    <li>A measurement of ‘spread’: this scatter plot describes the degree to which members differ in the perceived importance of team dynamics versus project status; for example: there might be team members who prioritize and value team dynamics more than project progress and vice versa. If members in a group show significant differences in the perception of the x and y axes, a team might have a problem; this is indicated explicitly with a ‘red flag’</li>
                                    <li>A measurement of an individual score (x, y): if team members locate themselves in the red area (Low importance of team dynamics and Low importance of project status) this should be interpreted as a ‘red flag’ as such (this represents an independent indicator from the measure of spread).</li>
                                    </ol>"
                                stripHtml={false} />
                        </div>
                        <Bubble
                            data={this.state.chartData}
                            options={this.state.chartOptions}
                            width={1}
                            height={1}
                            plugins={[ChartPlugins.DualAnswerChartPlugin(this.state.avgAreaData)]}
                        />
                    </div>
                </div>
            </div>
        );
    }

    _SetChartOptions(data, questions) {
        let pointColor = primaryColor;

        this.setState({
            chartOptions: {
                legend: false,
                elements: {
                    point: {
                        radius: 6,
                        backgroundColor: pointColor,
                    },
                },
                layout: {
                    padding: {
                        top: 15,
                    }
                },
                scales: {
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: questions.y.text,
                        },
                        ticks: {
                            fontSize: 15,
                            stepSize: 50,
                            suggestedMin: 0,
                            suggestedMax: 100,
                        }
                    }],
                    xAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: questions.x.text,
                        },
                        ticks: {
                            fontSize: 15,
                            stepSize: 50,
                            suggestedMin: 0,
                            suggestedMax: 100,
                        }
                    }]
                },
                tooltips: {
                    callbacks:
                        this.state.showIndividualData ?
                        {
                            title: (items, data) => data.datasets[items[0].datasetIndex].label,
                            label: (item) =>
                                'X: ' + item.xLabel + ' Y: ' + item.yLabel
                        } : {
                            label: (item) =>
                                'X: ' + item.xLabel + ' Y: ' + item.yLabel
                        }
                }
            }
        });
    }

    _LoadDualAnswers() {
        MIAService.getInstance().getReport('collection-answers-v2', { teamId: this.state.teamId, questionId: this.state.questionId })
            .then(resp => resp.json())
            .then(result => {
                let data = null;
                let questions = null;
                let relativeQuestion = null;
                let otherTeamData = [];

                let teams = [];
                result.answers.forEach(v => {
                    if (v.Type === 'average' && !teams.includes(v.Name))
                        teams.push(v.Name);
                });

                // Dual answer data
                if (result.answers && result.answers.length > 0) {
                    questions = { x: null, y: null };

                    // Determine axis titles
                    this.state.questions.forEach(q => {
                        if (q.Collection === this.state.collectionId) {
                            if (!questions.x)
                                questions.x = { id: q.Id, text: (Utils.LimitString(Utils.StripHTMLFromString(q.QuestionText), 60)) };

                            if (!questions.y && questions.x && questions.x.id !== q.Id)
                                questions.y = { id: q.Id, text: (Utils.LimitString(Utils.StripHTMLFromString(q.QuestionText), 60)) };

                            if (q.QuestionText !== this.state.questionText)
                                relativeQuestion = Utils.StripHTMLFromString(q.QuestionText);
                        }
                    });

                    data = { datasets: [] };

                    result.answers.forEach(a => {
                        // Gather other teams data
                        if (a.Type === 'average') {
                            if (a.Name !== this.state.teamName) otherTeamData.push(a);
                            return;
                        }

                        // Process chart data
                        a.Name = Utils.CapitalizeString(a.Name);

                        let datasetIndex = data.datasets.findIndex(d => d.label === a.Name);
                        if (datasetIndex < 0)
                            datasetIndex = data.datasets.length;

                        let dataset = data.datasets[datasetIndex] ||
                            { borderWidth: 3, borderColor: primaryColor, label: a.Name, data: [{ x: null, y: null }] };

                        if (questions.x.id === a.QuestionId)
                            dataset.data[0].x = a.Answer;

                        if (questions.y.id === a.QuestionId)
                            dataset.data[0].y = a.Answer;

                        if (!dataset.backgroundColor && dataset.data[0].x && dataset.data[0].y)
                            dataset.backgroundColor = Utils.DetermineStatusColor(Utils.DetermineStatus((parseInt(dataset.data[0].x) + parseInt(dataset.data[0].y)) / 2));

                        data.datasets[datasetIndex] = dataset;
                    });

                    this._SetChartOptions(data, questions);
                }

                // Sort answers by user
                let users = [];
                let answersByUser = [];
                let i = 0;
                result.answers.forEach(a => {
                    if (a.Type === 'average')
                        return;

                    // Get user
                    let index = users.findIndex(b => b === a.Name);

                    if (index < 0) {
                        index = i;
                        users[index] = a.Name;
                        i++;
                    }

                    if (answersByUser[index] === undefined)
                        answersByUser[index] = [];
                    
                    answersByUser[index].push(a);
                });

                // Process avgAreaData
                let chInput = [];

                teams.forEach(t => {
                    if (t === this.state.teamName)
                        return;

                    let ti = {x: undefined, y: undefined};

                    otherTeamData.forEach(d => {
                        if (questions.x.id === d.QuestionId && d.Name === t)
                            ti.x = d.Answer;
                        else if (questions.y.id === d.QuestionId && d.Name === t)
                            ti.y = d.Answer;
                    });

                    if (ti.x !== undefined && ti.y !== undefined)
                        chInput.push(ti);
                });

                // Calculate convex hull and sort points
                let avgAreaData = this._SortPointsClockwise(convexHull(chInput));

                this.setState({
                    dualAnswerDataLoaded: true,
                    chartData: data,
                    dualAnswerQuestions: questions,
                    relativeQuestion: relativeQuestion,
                    answersByUser: answersByUser,
                    avgAreaData: avgAreaData,
                });
            });
    }

    _SortPointsClockwise(points) {
        if (points.length === 0)
            return points;

        points.sort((a,b)=>a.y - b.y);

        // Get center y
        const cy = (points[0].y + points[points.length -1].y) / 2;

        // Sort from right to left
        points.sort((a,b)=>b.x - a.x);

        // Get center x
        const cx = (points[0].x + points[points.length -1].x) / 2;

        // Center point
        const center = {x:cx,y:cy};

        // Pre calculate the angles as it will be slow in the sort
        // As the points are sorted from right to left the first point
        // is the rightmost

        // Starting angle used to reference other angles
        let startAng;
        points.forEach(point => {
            let ang = Math.atan2(point.y - center.y,point.x - center.x);
            if(!startAng){ startAng = ang }
            else {
                if(ang < startAng){  // ensure that all points are clockwise of the start point
                    ang += Math.PI * 2;
                }
            }
            point.angle = ang; // add the angle to the point
        });

        // Sort clockwise;
        return points.sort((a, b) => a.angle - b.angle);
    }

}

export default DualAnswerChart;