import React from 'react';
import Utils from "../Utils";

// Student feedback

const studentAllPositiveScores = `All green, congratulations, your project is running smoothly.
Do not forget to regularly evaluate with your team to discuss how things are going. Take some time to discuss what is going well and how things could be improved further.`;

const studentMixedScores = `Most things are going well, except for an orange or red light on %items%. 
Plan a moment to discuss these results as a team. Take some time to stop and think about how things are going and evaluate what is going well. And most importantly, how you as a team could improve things, especially in this area.`;

const studentMostlyNegativeScores = `Multiple indicators point to negative progress and/or teamwork. These indicators include %items%.
Discuss with your team and schedule a meeting with your coach.`;

// Teacher feedback

const teacherAllPositiveScores = `All green, all indicators point to the team doing well. 
The dashboard shows a green lights on %items%.
We have pointed out to them in the dashboard that although things are going well it is important to regularly evaluate with your team to discuss how things are going and discuss how things could be improved further.`;

const teacherMixedScores = `An indicator points to potential team trouble.
The dashboard shows an orange or red light on %items%. 
We have pointed this out to them in the dashboard and asked them to find a moment to discuss and evaluate what is going well and how to improve (further). You may want to check with the team how they are dealing with this and whether they need your help.`;

const teacherMostlyNegativeScores = `Multiple indicators point to team trouble. 
The dashboard shows red lights on %items%. 
We have pointed this out to them in the dashboard and asked them to find a moment to discuss and evaluate what is going well and how to improve (further). 
The team has also been asked to contact their coach. You may want to check with the team how they are dealing with this and whether they need your help.`;

function DetermineFeedback(resultSet, roleIsTeacher)
{
	if (resultSet.find(r => r.showFeedback === 'False'))
		return;

	let badResults = [];
	let neutralResults = [];
	let feedback = '';

	// Gather negative results
	resultSet.forEach(r => {
		let scoreStr = Utils.DetermineStatus(r.score);

		if (scoreStr === 'Bad')
			badResults.push(r);
		else if (scoreStr === 'Neutral')
			neutralResults.push(r);
	});

	// Select feedback text based on scores
	if (badResults.length === 1 || neutralResults.length > 0)
		feedback = roleIsTeacher ? teacherMixedScores : studentMixedScores;
	if (badResults.length > 1)
		feedback = roleIsTeacher ? teacherMostlyNegativeScores : studentMostlyNegativeScores;
	if (badResults.length === 0 && neutralResults.length === 0)
		feedback = roleIsTeacher ? teacherAllPositiveScores : studentAllPositiveScores;
	
	// Split feedback on new lines
	let feedbackRows = feedback.split('\n');

	// Combine negative results
	let combinedNegativeResults = badResults.concat(neutralResults);

	// Render
	return (
		<div>
			{
				feedbackRows.map((r, index) => {
					// Insert emphasized items in feedback
					if (r.includes('%items%')) {
						let si = r.split('%items%');

						let items = [];
						combinedNegativeResults.forEach((v, i) => {
							items.push(<strong key={i}>{v.questionTitle}</strong>);

							if (i < combinedNegativeResults.length - 2)
								items.push(', ');
							else if (i === combinedNegativeResults.length - 2)
								items.push(' and ');
						});

						return (<p key={index}>{si[0]}{items}{si[1]}</p>);
					}

					// No items, print feedback directly
					return <p key={index}>{r}</p>;
				})
			}
		</div>
	);
}

export default { DetermineFeedback };