import React from 'react';
import { Line } from 'react-chartjs-3';
import ChartPlugin from './ChartPlugins';

class PeriodicAnswersChart extends React.Component {

    constructor(props) {
        super(props);

        this.chartOptions = {
            legend: this.props.legend,
            elements: {
                point: {
                    radius: 6,
                },
            },
            layout: {
                padding: {
                    top: 15,
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        stepSize: 50,
                        suggestedMin: 0,
                        suggestedMax: 100,
                    }
                }],
            },
            tooltips: {
                callbacks: {
                    title: (items, data) => {
                        let item = items[0];
                        return 'Moment ' + item.label;
                    },
                    label: (item) => {
                        return item.value + '%';
                    }
                }
            }
        }

        this.state = {
            chartData: this.props.dataSets,
        }
    }

    render() {
        return (
            <Line
                data={this.state.chartData}
                options={this.chartOptions}
                width={2}
                height={1}
                plugins={[ChartPlugin.PeriodicChartPlugin]}
            />
        );
    }
}

export default PeriodicAnswersChart;