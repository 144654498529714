import About from "./student/About";
import Login from "./Login";
import NotFound from "./NotFound";
import Assignment from "./student/assignment/Assignment";
import TeamReport from "./student/TeamReport";
import Logout from "./Logout";
import Teams from "./teacher/Teams";
import TeamAnswerDashboard from "./teacher/TeamAnswerDashboard";
import { TeamScoreList } from "../shared/team-score/TeamScoreList";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Maintenance from "./Maintenance";

const Routes = [
  {
    id: "page-not-found",
    path: "/page-not-found",
    title: "Page not found",
    component: NotFound,
  },
  {
    id: "maintenance",
    // path: "*",
    path: "/503",
    component: Maintenance,
  },
  {
    id: "login",
    path: "/",
    title: 'Login',
    includeInNavBar: true,
    exact: true,
    component: Login,
  },
  {
    id: "login-token",
    path: "/auth/:token",
    exact: true,
    component: Login,
  },
  {
    id: "logout",
    path: "/logout",
    exact: true,
    component: Logout,
  },
  {
    id: "forgot-password",
    path: "/forgot-password",
    title: "Forgot your password?",
    exact: true,
    component: ForgotPassword,
  },
  {
    id: "reset-password",
    path: "/reset-password/:validationToken",
    title: "Reset your password",
    exact: true,
    component: ResetPassword,
  },
  {
    id: "about",
    path: "/about/:code",
    title: "Questionnaire",
    exact: true,
    private: true,
    includeInNavBar: true,
    roles: ['Learner'],
    component: About,
  },
  {
    id: "assignment",
    path: "/assignment/:code",
    title: "Questionnaire",
    exact: true,
    private: true,
    includeInNavBar: false,
    roles: ['Learner'],
    component: Assignment,
  },
  {
    id: "assignment-question",
    path: "/assignment/:code/:questionIndex/:totalNumberOfQuestions",
    title: "Questionnaire",
    exact: true,
    private: true,
    includeInNavBar: false,
    roles: ['Learner'],
    component: Assignment,
  },
  {
    id: "team-dashboard",
    path: "/team-dashboard",
    title: "Team Dashboard",
    includeInNavBar: true,
    exact: true,
    private: true,
    roles: ['Learner'],
    component: TeamScoreList,
  },
  {
    id: "teams",
    path: "/teams",
    title: "Teacher Dashboard",
    description: "Here you'll find a team health status for each of the team(s). Click on \"Show results\" to get a more detailed overview.",
    includeInNavBar: true,
    exact: true,
    private: true,
    roles: ['Instructor', 'Mentor'],
    component: Teams,
  },
  {
    id: "team-answer-dashboard",
    path: "/team/:teamId",
    title: "Teacher Dashboard",
    description: "The Team Overview dashboard will show you an overview of how the team(s) has/have answered the survey questions. After you click on a question you will find the question details. For example you can find survey results over-time and individual student answers.",
    exact: true,
    private: true,
    roles: ['Instructor', 'Mentor'],
    component: TeamAnswerDashboard,
  },
  {
    id: "team-question-score",
    path: "/team/:teamId/:questionId",
    title: "Teacher Dashboard",
    description: "The Question Detail View will give you an overview of how the selected question has been answered. The answers of individual students are displayed on the left. The results over-time are shown in the graph on the right.",
    exact: true,
    private: true,
    roles: ['Instructor', 'Mentor'],
    component: TeamAnswerDashboard,
  },
  {
    id: "team-report",
    path: "/team-report",
    title: "Team Report",
    includeInNavBar: false,
    exact: true,
    private: true,
    roles: ['Learner'],
    component: TeamReport,
  },
];

export default Routes;