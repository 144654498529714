import React from 'react';

function ErrorMessage(props) {

    if (props.error) {

        let error = props.error;

        if (error === "NetworkError when attempting to fetch resource.")
            error = "A network error has occurred. Please check your internet connection.";

        return (
            <div className="row justify-content-center">
                <div className="col-auto alert alert-danger" role="alert">{error}{props.link && <br/>}{props.link || null}</div>
            </div>
        );
    }
    return null;
}

export default ErrorMessage;