import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle as circleRegular } from '@fortawesome/free-regular-svg-icons';
import { primaryColor } from '../../scss/styles.scss';
import Utils from './Utils';

class StatusIcon extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            status: this.props.status,
        }
    }

    render() {
        return (
            <div className={"status-icon " + this.props.className}>
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon color={primaryColor} icon={circleRegular} size="2x" />
                    <FontAwesomeIcon color={Utils.DetermineStatusColor(Utils.DetermineStatus(this.state.status))} icon={faCircle} size="2x" transform="shrink-6" />
                </span>
            </div>
        )
    }

}

export default StatusIcon;