import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import MIAService from '../../services/MIA/MIAService';
import ErrorMessage from '../shared/ErrorMessage';
import LoadIndicator from '../shared/LoadIndicator';

class ForgotPassword extends React.Component {

    initialFormState = "col-md-8 col-lg-5 needs-validation";

    constructor(props) {
        super(props);

        this.state = {
            mail: '',
            formClass: this.initialFormState,
            error: null,
            processing: false,
            passwordReset: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ formClass: this.initialFormState + ' was-validated' })

        if (!event.target.checkValidity())
            return;

        this.setState({ processing: true });

        MIAService.getInstance().passwordResetRequest(this.state.mail)
            .then(resp => resp.status)
            .then(res => {
                if (res === 200)
                    this.setState({ processing: false, passwordReset: true });
            })
            .catch(error => this.setState({ processing: false, error: error.message, formClass: this.initialFormState }));
    }

    handleChange(event) {
        this.setState({ mail: event.target.value });
    }

    render() {
        return (
            <div className="row justify-content-center">
                {this.state.passwordReset ? (
                    <div className="col-5">
                        <p className="alert alert-primary">Your request to reset your password has been received. In a few moments, you'll receive an email with a link to reset your password.</p>
                    </div>
                ) :
                    (!this.state.processing ?
                        <form className={this.state.formClass} onSubmit={this.handleSubmit} noValidate>
                            <p>If you've forgotten your password, fill in your email address below and press send. We'll send you a link to reset your password.</p>

                            <ErrorMessage error={this.state.error} />

                            {/* User */}
                            <div className="form-group row">
                                <div className="input-group col-sm-12">
                                    <div className="input-group-prepend">
                                        <label htmlFor="mail" className="input-group-text">
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </label>
                                    </div>
                                    <input id="mail" type="email" className="form-control form-control-lg" placeholder="..." onChange={this.handleChange} value={this.state.mail} required />
                                    <div className="invalid-feedback">
                                        Please provide a valid email address.
                            </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <Link className="btn btn-primary btn-lg" to="/">Back to login</Link>
                                </div>
                                <div className="col-6">
                                    <button type="submit" className="btn btn-primary btn-lg float-right">Send</button>
                                </div>
                            </div>
                        </form> :
                        <LoadIndicator />)}
            </div>
        );
    }

}

export default ForgotPassword;