import React from 'react';
import ReactLoading from 'react-loading';
import { secondaryColor } from '../../scss/styles.scss';

function LoadIndicator() {

    return (
        <div className="row justify-content-center">
            <ReactLoading type="bubbles" color={secondaryColor} />
        </div>
    );

}

export default LoadIndicator;