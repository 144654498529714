import React from "react";

import { secondaryColor } from "../../scss/styles.scss";

function Maintenance() {
    return (
        <div className="NotFound">
            <div className="row justify-content-center">
                <div className="col-md-8"  style={{color:secondaryColor}}>
                    <h1>We’re experiencing a server malfunction</h1>
                    <p>
                        This week you do not need to fill out your weekly
                        update. Your new OnCourse mini-survey will be sent to
                        you next week. Apologies for the inconvenience.
                    </p>
                    {/* <h1> <FontAwesomeIcon icon={icon} size="4x" color={secondaryColor} /> </h1> */}
                </div>
            </div>
        </div>
    );
}

export default Maintenance;
