import React from 'react';
import StatusIcon from '../../../../shared/StatusIcon';
import AssignmentControls from '../AssignmentControls';

class SliderModule extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            moduleData: this.props.data,
            answerCallback: this.props.answerCallback,
            answer: this.getRawAnswer(this.props.answer),
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.validate = this.validate.bind(this);
    }

    getSessionAnswer(answer) {
        return {
            questionId: this.state.moduleData.id,
            answer: this.state.answer,
        };
    }

    getRawAnswer(sessionAnswer) {
        return (sessionAnswer && sessionAnswer.answer !== undefined ? sessionAnswer.answer : 50);
    }

    handleInputChange(event, snapToValue) {
        event.preventDefault();
        let answer = snapToValue !== undefined ? snapToValue : event.target.value;
        this.setState({ answer: answer });
    }

    validate(event) {
        event.preventDefault();

        if (!event.target.checkValidity())
            return;

        this.state.answerCallback(this.getSessionAnswer(this.state.answer));
    }

    render() {
        return (
            <form onSubmit={this.validate}>
                <div className="row justify-content-center">
                    <div className="col-12">
                        {/* Slider */}
                        <input id="slider" type="range" className="gradient custom-range" value={this.state.answer} onChange={this.handleInputChange} />

                        {/* Colored icons */}
                        <div className="row justify-content-between">
                            <div className="col-auto">
                                <button className="status-icon-btn" onClick={(e) => this.handleInputChange(e, 0)}>
                                    <StatusIcon status="Bad" />
                                </button>
                            </div>
                            <div className="col-auto">
                                <button className="status-icon-btn" onClick={(e) => this.handleInputChange(e, 50)}>
                                    <StatusIcon status="Neutral" />
                                </button>
                            </div>
                            <div className="col-auto">
                                <button className="status-icon-btn" onClick={(e) => this.handleInputChange(e, 100)}>
                                    <StatusIcon status="Good" />
                                </button>
                            </div>
                        </div>

                        {/* Labels */}
                        <div className="row justify-content-between">
                            <div className="slider-label col-auto">{this.state.moduleData.labels[0].description}</div>
                            <div className="slider-label col-auto">{this.state.moduleData.labels[1].description}</div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-auto">
                        <small id="sliderHelp" className="form-text text-muted">Move the slider to the desired position.</small>
                    </div>
                </div>

                <AssignmentControls />

            </form>
        );
    }

}

export default SliderModule;