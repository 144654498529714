import React from 'react';
import TeamScoreListItem from './TeamScoreListItem';
import LoadIndicator from '../LoadIndicator';
import MIAService from '../../../services/MIA/MIAService';
import { Link, Route } from 'react-router-dom';
import ErrorMessage from '../ErrorMessage';
import InstructionToggle from '../instructions/InstructionToggle';

import Feedback from './TeamScoreListFeedback';

var ListItemTypes = {
    default: {},
    link: { component: Link },
}

class TeamScoreList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            responseRatesLoaded: false,
            questionAveragesLoaded: false,
            scores: [],
            teamId: this.props.teamId,
            listItemType: this.props.listItemType || ListItemTypes.default,
            responseRate: null,
        }
    }

    componentDidMount() {
        let teamId = this.state.teamId;

        // Use user session groups to determine teamId if teamId is not set
        if (teamId === undefined)
            teamId = MIAService.getInstance().userSession.groups[MIAService.getInstance().userSession.groups.length - 1].id;

        // Load question averages
        MIAService.getInstance().getReport('3106c0bb-fb20-4086-b69c-cfebf50eb730', { teamId: teamId })
            .then(resp => resp.json())
            .then(result => {
                this.setState({
                    questionAveragesLoaded: true,
                    scores: result.averages,
                });
            })
            .catch(error => this.setState({ error: error.message }));
        
        // Load response rate
        MIAService.getInstance().getReport("e4763f92-6981-480d-b529-e66a8f8e75c8")
            .then((req) => req.ok && req.json())
            .then((res) => {
                let t = res.responseRates.find((e) => {
                    return e.TeamId === teamId;
                });

                this.setState({
                    responseRatesLoaded: true,
                    responseRate: t ? t.RespRate : null
                });
            })
            .catch((error) =>
                this.setState({ error: error.message })
            );
    }

    componentDidUpdate(prevProps) {
        if (this.props.teamId !== prevProps.teamId)
            this.setState({ questionAveragesLoaded: false, teamId: this.props.teamId });
    }

    render() {
        return (
            this.state.error ? <ErrorMessage error={this.state.error} /> : (
                <div>
                    {(this.state.listItemType !== ListItemTypes.link) &&
                        <div className="row justify-content-center mb-4">
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col">
                                        {/* Response rate */}
                                        {this.state.responseRatesLoaded ? (
                                            this.state.responseRate &&
                                                <p className="mt-2">Response rate: {this.state.responseRate}%</p>
                                        ) : (
                                            <LoadIndicator />
                                        )}
                                    </div>

                                    <div className="col align-self-end">
                                        <Link className="btn btn-primary btn-lg float-right" to={"/team-report"}>show report</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.questionAveragesLoaded ? (
                        (this.state.scores && this.state.scores.length > 0 ? (
                            <div className="overview-list row justify-content-center">
                                <div className="col-lg-8">
                                    {this.state.listItemType === ListItemTypes.link &&
                                        <div className="mt-lg-5 mb-3">
                                            {/* Add team-report link */}
                                            <Link className="btn btn-primary btn-lg mr-3" to={'/team/' + this.state.teamId + '/team-report'}>Team Report</Link>
                                            <InstructionToggle className="mr-3" description="The report students see and use in their reflection" />

                                            {/* Add overview link */}
                                            <Link className="btn btn-primary btn-lg ml-3 ml-md-5 mr-3" to={'/team/' + this.state.teamId + '/overview'}>Overview</Link>
                                            <InstructionToggle description="Overview of all questions the team members answered in their last questionnaire" />
                                        </div>}
                                    <div className="list-group list-group-flush">
                                        <div>
                                            <h3>Latest results</h3>
                                            <hr />
                                        </div>

                                        {Feedback.DetermineFeedback(this.state.scores, !MIAService.getInstance().userSession.includesRoles(['Learner']))}

                                        {this.state.scores.map((score, index) => {
                                            return <Route key={index} render={routeProps => <TeamScoreListItem {...routeProps} index={index + 1} listItemType={this.state.listItemType} score={score} />} />;
                                        })}
                                    </div>
                                </div>
                            </div>
                        ) : (
                                <div className="row justify-content-center">
                                    <div className="col-md-8 col-lg-6">
                                        <p className="alert alert-primary">There are no results for this team yet.</p>
                                    </div>
                                </div>
                            ))
                    ) : (
                            <LoadIndicator />
                        )}
                </div>
            )
        );
    }

}

export { TeamScoreList, ListItemTypes };