import React from 'react';
import { Link } from 'react-router-dom';

import ErasmusLogo from '../../../assets/erasmus-uni-logo.png';
import MIAService from '../../../services/MIA/MIAService';

function About(props) {

  let assignmentPath = (props.match && "/assignment/" + props.match.params.code);

  return (MIAService.getInstance().userSession.activity ? (
    <div className="row">
      <div id="About" className="col-lg-8">

        <img src={ErasmusLogo} width="100px" className="img-thumbnail" alt="Erasmus University Logo" />
        <p>Welcome to the MiM Consutaltancy OnCourse App.</p>

        <h2>Why On Course?</h2>
        <p>On Course helps you identify possible issues within your team. It helps you and your team to discuss these and steer clear of them to achieve the best possible team experience and project outcome.</p>

        <h2>How does it work?</h2>
        <p>Fill out the mini survey. And get valuable insights on your team and project. Filling out the mini-survey takes 5 minutes.</p>

        <Link className="btn btn-primary btn-lg" to={assignmentPath}>Next</Link>

      </div>
    </div>
  ) : (
    <div className="row justify-content-center">
      <div id="About" className="col-md-8">
        <p className="alert alert-primary">There's no questionnaire available at the moment. You'll be notified if there's a questionnaire available.</p>
      </div>
    </div>
  ));
}

export default About;