import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDizzy } from '@fortawesome/free-regular-svg-icons';

import { secondaryColor } from '../../scss/styles.scss';

function NotFound() {
    return (
        <div className="NotFound">
            <h1><FontAwesomeIcon icon={faDizzy} size="4x" color={secondaryColor} /></h1>
            <p>Sorry, we couldn't find that page...</p>
        </div>
    );
}

export default NotFound;